import {
  getRemoteConfig,
  getValue,
  getString,
  getBoolean,
  fetchAndActivate,
} from "firebase/remote-config";

export const useRemoteConfig = () => {
  const configInstance = computed(() => {
    const app = useFirebaseApp();

    return getRemoteConfig(app);
  });

  const activate = async () => {
    try {
      // configInstance.value.settings.minimumFetchIntervalMillis = 10;

      await fetchAndActivate(configInstance.value);
    } catch (error) {
      // Empty
    }
  };

  const getConfigValue = (key: string) => {
    return getValue(configInstance.value, key);
  };

  const getConfigStringValue = (key: string) => {
    return getString(configInstance.value, key);
  };

  const getBoolValue = (key: string): boolean => {
    return getBoolean(configInstance.value, key);
  };

  const getJSONValue = (key: string) => {
    try {
      return JSON.parse(getConfigStringValue(key));
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  return {
    activate,
    getConfigValue,
    getConfigStringValue,
    getBoolValue,
    getJSONValue,
  };
};
